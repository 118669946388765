import React, {useState, useEffect} from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Button from "../elements/Button";
import {uchicagoCourses} from "../../assets/UChicago";
import {notreDameCourses} from "../../assets/Notre Dame";
import {rpiCourses} from "../../assets/RPI";
import {dartmouthCourses} from "../../assets/Dartmouth";
import {northeasternCourses} from "../../assets/Northeastern";
import {westernUniversityCourses} from "../../assets/Western University";
import {umassCourses} from "../../assets/UMass";

let schools = [
  "UChicago",
  "Notre Dame",
  "RPI",
  "Dartmouth",
  "Northeastern",
  "Western University",
  "UMass",
];
let coursesData = [
  uchicagoCourses,
  notreDameCourses,
  rpiCourses,
  dartmouthCourses,
  northeasternCourses,
  westernUniversityCourses,
  umassCourses,
];
let termTimes = [
  1709625600, 1709625600, 1709625600, 1709625600, 1709625600, 1709625600,
  1709625600, 1723217440,
];
let userSchool = window.sessionStorage.getItem("userSchool");

let classes = [];
let classTitles = [];
let classTitlesNoDuplicates = [];

if (userSchool != null && schools.includes(userSchool)) {
  for (var g = 0; g < coursesData[schools.indexOf(userSchool)].length; g++) {
    classes.push(coursesData[schools.indexOf(userSchool)][g].course);
    classTitles.push(
      `${coursesData[schools.indexOf(userSchool)][g].course.subject} - ${
        coursesData[schools.indexOf(userSchool)][g].course.name
      }`
    );
  }
}

classTitlesNoDuplicates = [...new Set(classTitles)];

function CRMDash() {
  const [allData, setAllData] = useState([]);
  const [consolidatedData, setConsolidatedData] = useState([]);
  const [selectedName, setSelectedName] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("dateTimeReverse");
  const [accessKey, setAccessKey] = useState("");
  const [giveAccess, setGiveAccess] = useState(
    window.sessionStorage.getItem("urgood2go") == "greatthanks" ? true : false
  );

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("grabLeads")
      .orderBy(sortColumn, sortOrder)
      .onSnapshot((querySnapshot) => {
        const allData = [];
        querySnapshot.forEach((doc) => {
          allData.push(doc.data());
        });
        setAllData(allData);
      });
    return unsubscribe;
  }, [sortColumn, sortOrder]);

  useEffect(() => {
    const consolidatedData = allData.reduce((acc, cur) => {
      const index = acc.findIndex((item) => item.phone === cur.phone);
      if (index === -1) {
        acc.push({name: cur.name, phone: cur.phone, dateTime: cur.dateTime});
      } else {
        if (cur.dateTime > acc[index].dateTime) {
          acc[index].dateTime = cur.dateTime;
        }
      }
      return acc;
    }, []);
    setConsolidatedData(consolidatedData);
  }, [allData]);

  const handleSortBy = (field) => {
    if (field === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(field);
      setSortOrder("asc");
    }
  };

  const handleNameClick = (name) => {
    setSelectedName(name);
  };

  const handleBackButtonClick = () => {
    setSelectedName(null);
  };

  const getSortIndicator = (field) => {
    if (field === sortColumn) {
      return sortOrder === "asc" ? " ▲" : " ▼";
    }
    return "";
  };

  function handleSignMeIn() {
    if (accessKey == "jackgec") {
      window.sessionStorage.setItem("urgood2go", "greatthanks");
      setGiveAccess(true);
    }
  }

  const getQuarterFromDate = (date) => {
    const month = new Date(date).getMonth();
    if (month >= 7 && month < 10) {
      return "Fall";
    } else if (month >= 10 || (month >= 0 && month < 1)) {
      return "Winter";
    } else if (month >= 1 && month < 4) {
      return "Spring";
    } else {
      return "Summer";
    }
  };

  const quarterNames = ["Fall", "Winter", "Spring", "Summer"];
  const quarterColors = [
    "rgb(246, 142, 23,0.1)",
    "rgb(13, 98, 255,0.1)",
    "rgb(25, 224, 15,0.1)",
    "rgb(218,35,255,0.1)",
  ];

  if (selectedName) {
    const entries = allData
      .filter(
        (datum) =>
          datum.phone ===
          consolidatedData.find((item) => item.name === selectedName).phone
      )
      .sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    return (
      <div style={{padding: "4rem"}}>
        <Button
          className="button button-primary button-wide-mobile button-sm"
          onClick={handleBackButtonClick}
        >
          Back
        </Button>
        <div
          style={{
            marginTop: "1.5rem",
            marginBottom: "1rem",
            fontSize: "36px",
            fontWeight: "bold",
          }}
        >
          {selectedName}
        </div>
        <table>
          <thead>
            <tr>
              <th>Notifications On?</th>
              <th>Class Subject</th>
              <th>Class Name</th>
              <th>Class Sections</th>
              <th onClick={() => handleSortBy("dateTime")}>
                Date Time{getSortIndicator("dateTime")}
              </th>
              <th>Got Class</th>
              <th>Lab Selected</th>
              <th>Referrer Name</th>
              <th>Referrer Phone</th>
            </tr>
          </thead>
          <tbody>
            {entries.map((entry, index) => (
              <>
                <tr>
                  {index == 0 ? (
                    <td
                      colSpan="9"
                      style={{
                        backgroundColor:
                          quarterColors[
                            quarterNames.indexOf(
                              getQuarterFromDate(entry.dateTime * 1000)
                            )
                          ],
                        fontWeight: "bold",
                        borderBottomColor: "grey",
                        borderBottomWidth:
                          index == 0 ||
                          getQuarterFromDate(entry.dateTime * 1000) !==
                            getQuarterFromDate(
                              entries[index - 1].dateTime * 1000
                            )
                            ? "2px"
                            : "0px",
                      }}
                    >
                      <div style={{height: "1.5rem", width: "100%"}}>
                        <div>{getQuarterFromDate(entry.dateTime * 1000)}</div>
                      </div>
                    </td>
                  ) : null}
                  {index != 0
                    ? getQuarterFromDate(entry.dateTime * 1000) !==
                        getQuarterFromDate(
                          entries[index - 1].dateTime * 1000
                        ) && (
                        <td
                          colSpan="9"
                          style={{
                            backgroundColor:
                              quarterColors[
                                quarterNames.indexOf(
                                  getQuarterFromDate(entry.dateTime * 1000)
                                )
                              ],
                            fontWeight: "bold",
                            borderBottomColor: "grey",
                            borderBottomWidth:
                              index == 0 ||
                              getQuarterFromDate(entry.dateTime * 1000) !==
                                getQuarterFromDate(
                                  entries[index - 1].dateTime * 1000
                                )
                                ? "2px"
                                : "0px",
                          }}
                        >
                          <div style={{height: "1.5rem", width: "100%"}}>
                            <div>
                              {getQuarterFromDate(entry.dateTime * 1000)}
                            </div>
                          </div>
                        </td>
                      )
                    : null}
                </tr>
                <tr key={index}>
                  <td
                    style={{
                      backgroundColor:
                        entry.gotClass == false &&
                        entry.classSections.length > 0 &&
                        entry.dateTime > termTimes[0]
                          ? "rgb(36, 252, 3,0.8)"
                          : "rgb(253, 14, 53,0.8)",
                      fontWeight: "bold",
                    }}
                  >
                    {entry.gotClass == false &&
                    entry.classSections.length > 0 &&
                    entry.dateTime > termTimes[0]
                      ? "On"
                      : "Off"}
                  </td>
                  <td>{entry.classSubject}</td>
                  <td>
                    {
                      classes.filter(
                        (course) =>
                          `${course.subject.toLowerCase()}` ==
                          `${entry.classSubject.toLowerCase()}`
                      )[0].name
                    }
                  </td>
                  <td>{entry.classSections?.join(", ") || ""}</td>
                  <td>{new Date(entry.dateTime * 1000).toLocaleString()}</td>
                  <td>{entry.gotClass ? "Yes" : "No"}</td>
                  <td>{entry.labSelected ? "Yes" : "No"}</td>
                  <td>{entry.referrerName}</td>
                  <td>{entry.referrerPhone}</td>
                </tr>
                {entry.dateTime > termTimes[0] ? (
                  <tr>
                    <td colSpan="8">
                      {
                        classes.filter(
                          (course) =>
                            `${course.subject.toLowerCase()}` ==
                            `${entry.classSubject.toLowerCase()}`
                        )[0].description
                      }
                    </td>
                  </tr>
                ) : null}
              </>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div style={{padding: "4rem"}}>
      {giveAccess == false ? (
        <div>
          <input
            type="text"
            value={accessKey}
            onChange={(event) => setAccessKey(event.target.value)}
          />
          <Button
            className="button button-primary button-wide-mobile button-sm"
            onClick={handleSignMeIn}
          >
            Check Me Out
          </Button>
        </div>
      ) : (
        <div>
          <table>
            <thead>
              <tr>
                <th onClick={() => handleSortBy("name")}>
                  Name{getSortIndicator("name")}
                </th>
                <th onClick={() => handleSortBy("phone")}>
                  Phone{getSortIndicator("phone")}
                </th>
                <th onClick={() => handleSortBy("dateTime")}>
                  Date Time{getSortIndicator("dateTime")}
                </th>
              </tr>
            </thead>
            <tbody>
              {consolidatedData.map((datum, index) => (
                <tr key={index} onClick={() => handleNameClick(datum.name)}>
                  <td>{datum.name}</td>
                  <td>{datum.phone}</td>
                  <td>{new Date(datum.dateTime * 1000).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default CRMDash;
