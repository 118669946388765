import React, {useEffect, useState} from "react";
import classNames from "classnames";
import {SectionProps} from "../../utils/SectionProps";
import {Link} from "react-router-dom";
import SectionHeader from "./partials/SectionHeader";
import Button from "../elements/Button";
import Select from "../elements/Select";
import Checkbox from "../elements/Checkbox";
import HeroFull from "./HeroFull";
import mixpanel from "mixpanel-browser";
import firebase from "firebase/compat/app";
import StripeCheck from "../sections/StripeCheck";
import {PaymentElement, CardElement, useStripe} from "@stripe/react-stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import Fuse from "fuse.js";
import {uchicagoCourses} from "../../assets/UChicago";
import {notreDameCourses} from "../../assets/Notre Dame";
import {rpiCourses} from "../../assets/RPI";
import {dartmouthCourses} from "../../assets/Dartmouth";
import {northeasternCourses} from "../../assets/Northeastern";
import {westernUniversityCourses} from "../../assets/Western University";
import {umassCourses} from "../../assets/UMass";
import Pricing from "../sections/PricingLaunchPage";
require("firebase/auth");

let isItAddDrop = [true, false, true, true, false, false, false];

window.mobileCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

const firebaseConfig = {
  apiKey: "AIzaSyAQ1DgZyHU2UDmQ_JDgrEg6th8fUuMOFQg",
  authDomain: "uchiprereg.firebaseapp.com",
  projectId: "uchiprereg",
  storageBucket: "uchiprereg.appspot.com",
  messagingSenderId: "963203646451",
  appId: "1:963203646451:web:70d27bc0d01c0704650886",
  measurementId: "G-1FMVGVFPGW",
};

firebase.initializeApp(firebaseConfig);

var mouseDown = false;

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

let schools = [
  "UChicago",
  "Notre Dame",
  "RPI",
  "Dartmouth",
  "Northeastern",
  "Western University",
  "UMass",
];
let coursesData = [
  uchicagoCourses,
  notreDameCourses,
  rpiCourses,
  dartmouthCourses,
  northeasternCourses,
  westernUniversityCourses,
  umassCourses,
];
let termTimes = [
  1709625600, 1709625600, 1709625600, 1709625600, 1709625600, 1709625600,
  1709625600, 1723217440,
];
let userSchool = window.sessionStorage.getItem("userSchool");

let classes = [];
let classTitles = [];
let classTitlesNoDuplicates = [];

// for(var g=0;g<uchicagoCourses.subjects.length;g++){
//   for(var p=0;p<uchicagoCourses.subjects[g].course.length;p++){
//     classes.push(uchicagoCourses.subjects[g].course[p])
//     classTitles.push(uchicagoCourses.subjects[g].course[p].number);
//   }
// }

if (userSchool != null && schools.includes(userSchool)) {
  for (var g = 0; g < coursesData[schools.indexOf(userSchool)].length; g++) {
    classes.push(coursesData[schools.indexOf(userSchool)][g].course);
    classTitles.push(
      `${coursesData[schools.indexOf(userSchool)][g].course.subject} - ${
        coursesData[schools.indexOf(userSchool)][g].course.name
      }`
    );
  }
}

classTitlesNoDuplicates = [...new Set(classTitles)];

let numClassesEval = [0];
if (window.sessionStorage.getItem("numClasses") != null) {
  numClassesEval = [];
  for (
    let j = 0;
    j < parseFloat(window.sessionStorage.getItem("numClasses"));
    j++
  ) {
    numClassesEval.push(0);
  }
}

let classSelected1New = [];
let classSelected2New = [];
let classSelected3New = [];
let classSelected4New = [];
let classSelected5New = [];

if (window.sessionStorage.getItem("classSectionsSelected1") != null) {
  classSelected1New.push(
    classes.filter(
      (course) =>
        course.subject.toLowerCase() ==
        window.sessionStorage.getItem("subjectSelected1").toLowerCase()
    )
  );
}

if (window.sessionStorage.getItem("classSectionsSelected2") != null) {
  classSelected2New.push(
    classes.filter(
      (course) =>
        course.subject.toLowerCase() ==
        window.sessionStorage.getItem("subjectSelected2").toLowerCase()
    )
  );
}

if (window.sessionStorage.getItem("classSectionsSelected3") != null) {
  classSelected3New.push(
    classes.filter(
      (course) =>
        course.subject.toLowerCase() ==
        window.sessionStorage.getItem("subjectSelected3").toLowerCase()
    )
  );
}

if (window.sessionStorage.getItem("classSectionsSelected4") != null) {
  classSelected4New.push(
    classes.filter(
      (course) =>
        course.subject.toLowerCase() ==
        window.sessionStorage.getItem("subjectSelected4").toLowerCase()
    )
  );
}

if (window.sessionStorage.getItem("classSectionsSelected5") != null) {
  classSelected5New.push(
    classes.filter(
      (course) =>
        course.subject.toLowerCase() ==
        window.sessionStorage.getItem("subjectSelected5").toLowerCase()
    )
  );
}

let sectionsSelected1New = [];
let sectionsSelected2New = [];
let sectionsSelected3New = [];
let sectionsSelected4New = [];
let sectionsSelected5New = [];

if (window.sessionStorage.getItem("classSectionsSelected1") != null) {
  let sectionsSelected1 = window.sessionStorage
    .getItem("classSectionsSelected1")
    .split(",");

  for (var e = 0; e < sectionsSelected1.length; e++) {
    sectionsSelected1New.push(parseInt(sectionsSelected1[e]));
  }
}

if (window.sessionStorage.getItem("classSectionsSelected2") != null) {
  let sectionsSelected2 = window.sessionStorage
    .getItem("classSectionsSelected2")
    .split(",");

  for (var e = 0; e < sectionsSelected2.length; e++) {
    sectionsSelected2New.push(parseInt(sectionsSelected2[e]));
  }
}

if (window.sessionStorage.getItem("classSectionsSelected3") != null) {
  let sectionsSelected3 = window.sessionStorage
    .getItem("classSectionsSelected3")
    .split(",");

  for (var e = 0; e < sectionsSelected3.length; e++) {
    sectionsSelected3New.push(parseInt(sectionsSelected3[e]));
  }
}

if (window.sessionStorage.getItem("classSectionsSelected4") != null) {
  let sectionsSelected4 = window.sessionStorage
    .getItem("classSectionsSelected4")
    .split(",");

  for (var e = 0; e < sectionsSelected4.length; e++) {
    sectionsSelected4New.push(parseInt(sectionsSelected4[e]));
  }
}

if (window.sessionStorage.getItem("classSectionsSelected5") != null) {
  let sectionsSelected5 = window.sessionStorage
    .getItem("classSectionsSelected5")
    .split(",");

  for (var e = 0; e < sectionsSelected5.length; e++) {
    sectionsSelected5New.push(parseInt(sectionsSelected5[e]));
  }
}

function SelectPlan() {
  useEffect(() => {
    const userSchool = sessionStorage.getItem("userSchool");
    if (!userSchool) {
      window.location.href = "/";
    }
  }, []);

  // let [alreadyUserNames, setAlreadyUserNames] = useState([])
  let [referrerPhone, setReferrerPhone] = useState(
    window.sessionStorage.getItem("referrerPhone") != null
      ? ""
      : window.sessionStorage.getItem("referrerPhone")
  );
  let [referrerName, setReferrerName] = useState(
    window.sessionStorage.getItem("referrerName") != null
      ? ""
      : window.sessionStorage.getItem("referrerName")
  );
  let [currentlyTracking, setCurrentlyTracking] = useState([]);
  let [allSubjects, setAllSubjects] = useState([]);

  useEffect(() => {
    firebase
      .firestore()
      .collection("grabLeads")
      .orderBy("dateTime")
      // .get()
      // .then((querySnapshot) => {
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.data().dateTime > termTimes[schools.indexOf(userSchool)]) {
            setAllSubjects((a) => [...a, doc.data()]);
          }

          //NEED THIS IF CONDITION TO MAKE SURE THE DATA IS ACCURATE FOR THE QUARTER
          if (
            doc.data().dateTime > termTimes[schools.indexOf(userSchool)] &&
            doc.data().gotClass != true &&
            doc.data().classSections.length > 0
          ) {
            setCurrentlyTracking((a) => [...a, doc.data().classSubject]);
          }
          // if(window.location.href.split('signup')[1].includes(`?referralPhoneInput=${doc.data().phone}`)){
          //   setReferrerPhone(doc.data().phone)
          //   setReferrerName(doc.data().name)

          // }else

          if (
            window.sessionStorage.getItem("referralPhone") != null &&
            window.sessionStorage.getItem("referralPhone") != "null" &&
            window.sessionStorage.getItem("referralPhone") != ""
          ) {
            setReferrerPhone(window.sessionStorage.getItem("referralPhone"));
            setReferrerName(window.sessionStorage.getItem("referralName"));
          }
        });

        // querySnapshot.docChanges().forEach((change) => {
        //   if (change.type === "added") {
        //     // setAlreadyUserNames(a =>[...a,change.doc.data().userPhone]);
        //     if(window.location.href.split('phoneInput=')[1].includes(change.doc.data().phone)){
        //       setReferrerPhone(change.doc.data().phone)
        //       console.log(change.doc.data().phone)
        //     }

        //   }
        // })
      });
  }, []);

  let currentDateInSeconds = new Date().getTime() / 1000;
  let [userName, setUserName] = useState(
    window.sessionStorage.getItem("userName") == null
      ? ""
      : window.sessionStorage.getItem("userName")
  );
  let [userPhone, setUserPhone] = useState(
    window.sessionStorage.getItem("userPhone") == null
      ? ""
      : window.sessionStorage.getItem("userPhone")
  );
  let [wantsEmails, setWantsEmails] = useState(
    window.sessionStorage.getItem("wantsEmails") == null
      ? "no"
      : window.sessionStorage.getItem("wantsEmails")
  );
  let [buttonClicked, setButtonClicked] = useState(false);

  let [numClasses, setNumClasses] = useState(
    window.sessionStorage.getItem("numClasses") == null ? [0] : numClassesEval
  );

  let [classTextInput1, setClassTextInput1] = useState(
    window.sessionStorage.getItem("classTextInput1") == null
      ? ""
      : window.sessionStorage.getItem("classTextInput1")
  );
  let [classSelected1, setClassSelected1] = useState(
    window.sessionStorage.getItem("classSectionsSelected1") == null
      ? []
      : classSelected1New[0]
  );
  let [subjectSelected1, setSubjectSelected1] = useState(
    window.sessionStorage.getItem("subjectSelected1") == null
      ? ""
      : window.sessionStorage.getItem("subjectSelected1")
  );
  let [classSectionsSelected1, setClassSectionsSelected1] = useState(
    window.sessionStorage.getItem("classSectionsSelected1") == null
      ? []
      : sectionsSelected1New
  );

  let [classTextInput2, setClassTextInput2] = useState(
    window.sessionStorage.getItem("classTextInput2") == null
      ? ""
      : window.sessionStorage.getItem("classTextInput2")
  );
  let [classSelected2, setClassSelected2] = useState(
    window.sessionStorage.getItem("classSectionsSelected2") == null
      ? []
      : classSelected2New[0]
  );
  let [subjectSelected2, setSubjectSelected2] = useState(
    window.sessionStorage.getItem("subjectSelected2") == null
      ? ""
      : window.sessionStorage.getItem("subjectSelected2")
  );
  let [classSectionsSelected2, setClassSectionsSelected2] = useState(
    window.sessionStorage.getItem("classSectionsSelected2") == null
      ? []
      : sectionsSelected2New
  );

  let [classTextInput3, setClassTextInput3] = useState(
    window.sessionStorage.getItem("classTextInput3") == null
      ? ""
      : window.sessionStorage.getItem("classTextInput3")
  );
  let [classSelected3, setClassSelected3] = useState(
    window.sessionStorage.getItem("classSectionsSelected3") == null
      ? []
      : classSelected3New[0]
  );
  let [subjectSelected3, setSubjectSelected3] = useState(
    window.sessionStorage.getItem("subjectSelected3") == null
      ? ""
      : window.sessionStorage.getItem("subjectSelected3")
  );
  let [classSectionsSelected3, setClassSectionsSelected3] = useState(
    window.sessionStorage.getItem("classSectionsSelected3") == null
      ? []
      : sectionsSelected3New
  );

  let [classTextInput4, setClassTextInput4] = useState(
    window.sessionStorage.getItem("classTextInput4") == null
      ? ""
      : window.sessionStorage.getItem("classTextInput4")
  );
  let [classSelected4, setClassSelected4] = useState(
    window.sessionStorage.getItem("classSectionsSelected4") == null
      ? []
      : classSelected4New[0]
  );
  let [subjectSelected4, setSubjectSelected4] = useState(
    window.sessionStorage.getItem("subjectSelected4") == null
      ? ""
      : window.sessionStorage.getItem("subjectSelected4")
  );
  let [classSectionsSelected4, setClassSectionsSelected4] = useState(
    window.sessionStorage.getItem("classSectionsSelected4") == null
      ? []
      : sectionsSelected4New
  );

  let [classTextInput5, setClassTextInput5] = useState(
    window.sessionStorage.getItem("classTextInput5") == null
      ? ""
      : window.sessionStorage.getItem("classTextInput5")
  );
  let [classSelected5, setClassSelected5] = useState(
    window.sessionStorage.getItem("classSectionsSelected5") == null
      ? []
      : classSelected5New[0]
  );
  let [subjectSelected5, setSubjectSelected5] = useState(
    window.sessionStorage.getItem("subjectSelected5") == null
      ? ""
      : window.sessionStorage.getItem("subjectSelected5")
  );
  let [classSectionsSelected5, setClassSectionsSelected5] = useState(
    window.sessionStorage.getItem("classSectionsSelected5") == null
      ? []
      : sectionsSelected5New
  );

  let [fuseSearch1, setFuseSearch1] = useState([]);
  let [fuseSearch2, setFuseSearch2] = useState([]);
  let [fuseSearch3, setFuseSearch3] = useState([]);
  let [fuseSearch4, setFuseSearch4] = useState([]);
  let [fuseSearch5, setFuseSearch5] = useState([]);

  let [showFuseList1, setShowFuseList1] = useState(true);
  let [showFuseList2, setShowFuseList2] = useState(true);
  let [showFuseList3, setShowFuseList3] = useState(true);
  let [showFuseList4, setShowFuseList4] = useState(true);
  let [showFuseList5, setShowFuseList5] = useState(true);

  let [consentRequired1, setConsentRequired1] = useState(false);
  let [consentRequired2, setConsentRequired2] = useState(false);
  let [consentRequired3, setConsentRequired3] = useState(false);
  let [consentRequired4, setConsentRequired4] = useState(false);
  let [consentRequired5, setConsentRequired5] = useState(false);

  let className;
  let topOuterDivider;
  let bottomOuterDivider;
  let topDivider;
  let bottomDivider;
  let hasBgColor;
  let invertColor;

  const outerClasses = classNames(
    // 'signin section',
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "signin-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const sectionHeader = {
    title: "Sign up",
    //paragraph: isItAddDrop==false?'We will text you when: ':''
  };

  return (
    <section
      // {...props}
      className={outerClasses}
    >
      <div
        className="container"
        // style={{width:'40%'}}
      >
        {/* <div className={innerClasses}> */}

        <Pricing onSelectPlanPage />

        {/* </div> */}
      </div>
    </section>
  );
}

SelectPlan.propTypes = propTypes;
SelectPlan.defaultProps = defaultProps;

export default SelectPlan;
