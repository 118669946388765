import React, {useEffect, useState} from "react";
import classNames from "classnames";
import {SectionProps} from "../../utils/SectionProps";
import Button from "../elements/Button";
import Image from "../elements/Image";
import SectionHeader from "./partials/SectionHeader";
import Accordion from "../elements/Accordion";
import AccordionItem from "../elements/AccordionItem";
import GenericSection from "./GenericSection";
import Carousel from "./LogoCarousel";
import firebase from "firebase/compat/app";

let schools = [
  "UChicago",
  "Notre Dame",
  "RPI",
  "Dartmouth",
  "Northeastern",
  "Western University",
  "UMass",
];
let isItAddDrop = [true, false, true, true, false, false, false];
let userSchool = window.sessionStorage.getItem("userSchool");

let freeUsersArray = [
  "+19178168373",
  "+12245091991",
  "+17863505278",
  "+17087108441",
  "+18033711796",
  "+18608374226",
  "+14243929994",
  "+19706857425",
  "+17737987563",
  "+17737888004",
  "+18477906321",
  "+17328815655",
  "+14149165211",
  "+12138148916",
  "+12488029462",
  "+18325422188",
  "+18608636325",
  "+15024456682",
  "+19047902120",
  "+13099896627",
  "+14193200766",
  "+17735104851",
  "+12027632522",
  "+13108099387",
  "+13474098111",
  "+19175289583",
  "+13095306085",
  "+14699997362",
  "+13472057774",
  "+17139279195",
  "+14808866096",
  "+14804344586",
  "+17182003002",
  "+15166972124",
  "+17879402437",
  "+12029998416",
  "+19375578085",
  "+12036130170",
  "+19257259639",
  "+15102489778",
  "+16307439920",
  "+17703095075",
  "+16507769083",
  "+15122307229",
  "+18477785574",
  "+66916963175",
  "+16086300910",
  "+19085653326",
  "+13144794621",
  "+13123752925",
  "+17739529401",
  "+16506002482",
  "+13127186343",
  "+14084422905",
  "+12029265758",
  "+14702345428",
  "+12028137163",
  "+13106999256",
  "+16177758386",
  "+19145485489",
  "+17138909124",
  "+17187043243",
  "+17084913408",
  "+14107035368",
  "+12024598041",
  "+19734204762",
  "+12624758033",
  "+16463509253",
  "+19085176922",
  "+12162690310",
  "+17204417279",
  "+17602201548",
  "+13129786746",
  "+14138013717",
  "+13098572498",
  "+16825530436",
  "+16263532428",
  "+15104074958",
  "+16468412006",
  "+19732168750",
  "+19546818915",
  "+14697664243",
  "+19176484872",
  "+18486675266",
  "+18054555281",
  "+17733068421",
  "+8801765100738",
  "+19257195571",
  "+16502831962",
  "+19738767588",
  "+18608397388",
  "+12032933339",
  "+17736336786",
  "+16023158203",
  "+16154502490",
  "+17735447799",
  "+15736399003",
  "+13802401726",
  "+16126707721",
  "+16128672132",
  //'+17037626410'
];

function HeroFull(props) {
  const [allUsers, setAllUsers] = useState([]);

  function clickLaunch() {
    window.location.href = "#launch";
  }
  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("grabLeads")
      .onSnapshot((querySnapshot) => {
        let allUsers = [];
        querySnapshot.forEach((doc) => {
          allUsers.push(doc.data());
        });
        setAllUsers(allUsers);
      });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    window.sessionStorage.setItem("userSchool", "UChicago");
  });

  window.sessionStorage.removeItem("upgradingFromPreferences");
  window.sessionStorage.removeItem("upgradingID1");
  window.sessionStorage.removeItem("upgradingID2");
  window.sessionStorage.removeItem("upgradingID3");
  window.sessionStorage.removeItem("upgradingID4");
  window.sessionStorage.removeItem("upgradingID5");
  window.sessionStorage.removeItem("signupPlan");

  const {
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...restProps
  } = props;

  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section
      {...restProps}
      className={outerClasses}
      //style={{marginTop:'0.5rem'}}
    >
      <div className="container-sm">
        <div
          className={innerClasses}
          style={{paddingTop: "0.5rem", margin: "0px"}}
        >
          <div
            className="hero-content"
            style={{
              // margin:'0px',
              marginTop:
                window.sessionStorage.getItem("userName") == null
                  ? "1rem"
                  : "1.7rem",
            }}
          >
            <h1
              className="mt-0 mb-16 reveal-from-top phone-header"
              data-reveal-delay="150"
            >
              Getting classes shouldn’t be this hard
            </h1>
            <p
              className="reveal-from-top phone-paragraph"
              data-reveal-delay="300"
            >
              We make it easy — we text you when a spot in a full class opens
            </p>
            <div
              className="hero-figure reveal-from-bottom mb-16"
              data-reveal-delay="600"
            >
              {isItAddDrop[schools.indexOf("UChicago")] == true ? (
                freeUsersArray.includes(
                  window.sessionStorage.getItem("userPhone")
                ) ? (
                  <Button tag="a" href="/getnotifications" color="primary">
                    Launch Notifications &nbsp;&#10140;
                  </Button>
                ) : allUsers
                    .map((a) => a.phone)
                    .includes(window.sessionStorage.getItem("userPhone")) ? (
                  <Button
                    tag="a"
                    href={`/preferences#${window.sessionStorage.getItem(
                      "userPhone"
                    )}`}
                    color="primary"
                    className="button-wide-mobile cta-phone"
                  >
                    Get Notifications &nbsp;&#10140;
                  </Button>
                ) : (
                  <Button
                    tag="a"
                    href="/signup"
                    color="primary"
                    className="button-wide-mobile cta-phone"
                  >
                    Get Notifications &nbsp;&#10140;
                  </Button>
                )
              ) : (
                <Button
                  tag="a"
                  href="/signup"
                  color="primary"
                  className="button-wide-mobile cta-phone"
                >
                  Sign Up &nbsp;&#10140;
                </Button>
              )}

              {/* <Button tag="a" onClick={clickLaunch} color='primary'className="button-wide-mobile cta-phone">
                  Sign Up &nbsp;&#10140;
            </Button> */}
            </div>
            <div className="mb-120 phone-carosel">
              {/* <Carousel /> */}
              <div style={{height: "10px"}}></div>
              <Image
                //className="has-shadow"
                className="phone"
                src={require("./../../assets/images/UChicagoLogo.png")}
                alt="Hero"
                height={80}
              />
            </div>
            <div className="reveal-from-bottom" data-reveal-delay="600">
              <Image
                //className="has-shadow"
                className="phone"
                src={require("./../../assets/images/adamcigs.png")}
                alt="Hero"
                height={204}
              />
            </div>
            <div style={{height: "50px"}}></div>
            <div>To own Class Clutch, contact (703) 762-6410</div>
          </div>
        </div>
      </div>
      <div style={{height: "1rem"}}></div> {/* This is the buffer */}
    </section>
  );
}

export default HeroFull;
