import React, {useEffect, useState, useRef} from "react";
import {useHistory} from "react-router-dom";
import classNames from "classnames";
import {SectionProps} from "../../utils/SectionProps";
import {Link} from "react-router-dom";
import SectionHeader from "./partials/SectionHeader";
import Button from "../elements/Button";
import Select from "../elements/Select";
import Checkbox from "../elements/Checkbox";
import HeroFull from "./HeroFull";
import mixpanel from "mixpanel-browser";
import firebase from "firebase/compat/app";
import StripeCheck from "../sections/StripeCheck";
import {PaymentElement, CardElement, useStripe} from "@stripe/react-stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import Fuse from "fuse.js";
require("firebase/auth");

window.mobileCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

let freeUsersArray = [
  "+19178168373",
  "+12245091991",
  "+17863505278",
  "+17087108441",
  "+18033711796",
  "+18608374226",
  "+14243929994",
  "+19706857425",
  "+17737987563",
  "+17737888004",
  "+18477906321",
  "+17328815655",
  "+14149165211",
  "+12138148916",
  "+12488029462",
  "+18325422188",
  "+18608636325",
  "+15024456682",
  "+19047902120",
  "+13099896627",
  "+14193200766",
  "+17735104851",
  "+12027632522",
  "+13108099387",
  "+13474098111",
  "+19175289583",
  "+13095306085",
  "+14699997362",
  "+13472057774",
  "+17139279195",
  "+14808866096",
  "+14804344586",
  "+17182003002",
  "+15166972124",
  "+17879402437",
  "+12029998416",
  "+19375578085",
  "+12036130170",
  "+19257259639",
  "+15102489778",
  "+16307439920",
  "+17703095075",
  "+16507769083",
  "+15122307229",
  "+18477785574",
  "+66916963175",
  "+16086300910",
  "+19085653326",
  "+13144794621",
  "+13123752925",
  "+17739529401",
  "+16506002482",
  "+13127186343",
  "+14084422905",
  "+12029265758",
  "+14702345428",
  "+12028137163",
  "+13106999256",
  "+16177758386",
  "+19145485489",
  "+17138909124",
  "+17187043243",
  "+17084913408",
  "+14107035368",
  "+12024598041",
  "+19734204762",
  "+12624758033",
  "+16463509253",
  "+19085176922",
  "+12162690310",
  "+17204417279",
  "+17602201548",
  "+13129786746",
  "+14138013717",
  "+13098572498",
  "+16825530436",
  "+16263532428",
  "+15104074958",
  "+16468412006",
  "+19732168750",
  "+19546818915",
  "+14697664243",
  "+19176484872",
  "+18486675266",
  "+18054555281",
  "+17733068421",
  "+8801765100738",
  "+19257195571",
  "+16502831962",
  "+19738767588",
  "+18608397388",
  "+12032933339",
  "+17736336786",
  "+16023158203",
  "+16154502490",
  "+17735447799",
  "+15736399003",
  "+13802401726",
  "+16126707721",
  "+16128672132",
  //'+17037626410'
];

const firebaseConfig = {
  apiKey: "AIzaSyAQ1DgZyHU2UDmQ_JDgrEg6th8fUuMOFQg",
  authDomain: "uchiprereg.firebaseapp.com",
  projectId: "uchiprereg",
  storageBucket: "uchiprereg.appspot.com",
  messagingSenderId: "963203646451",
  appId: "1:963203646451:web:70d27bc0d01c0704650886",
  measurementId: "G-1FMVGVFPGW",
};

firebase.initializeApp(firebaseConfig);

var mouseDown = false;

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

let schools = [
  "UChicago",
  "Notre Dame",
  "RPI",
  "Dartmouth",
  "Northeastern",
  "Western University",
  "UMass",
];
let isItAddDrop = [true, false, true, true, false, false, false];

function SelectSchool() {
  let currentDateInSeconds = new Date().getTime() / 1000;

  const [school, setSchool] = useState("");
  const [schoolAlert, setSchoolAlert] = useState("");

  let [addSchool, setAddSchool] = useState(false);
  let [newSchool, setNewSchool] = useState("");
  let [newSchoolError, setNewSchoolError] = useState("");
  let [submittedNewSchool, setSubmittedNewSchool] = useState(false);
  let [buttonClicked2, setButtonClicked2] = useState(false);

  let className;
  let topOuterDivider;
  let bottomOuterDivider;
  let topDivider;
  let bottomDivider;
  let hasBgColor;
  let invertColor;

  const outerClasses = classNames(
    "signin section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "signin-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const sectionHeader = {
    title: "Select Your School",
    // paragragph: 'The first 100 students get free access for this add period.'
  };

  // window.onclick = (event) => {console.log(document.getElementById('preorderTooltipText').getBoundingClientRect())};

  function submitSchool() {
    if (school == "") {
      setSchoolAlert("You must select a school to sign up for Class Clutch");
    } else {
      window.sessionStorage.setItem("userSchool", school);
      window.sessionStorage.removeItem("classTextInput1");
      window.sessionStorage.removeItem("classTextInput2");
      window.sessionStorage.removeItem("classTextInput3");
      window.sessionStorage.removeItem("classTextInput4");
      window.sessionStorage.removeItem("classTextInput5");
      window.sessionStorage.removeItem("subjectSelected1");
      window.sessionStorage.removeItem("subjectSelected2");
      window.sessionStorage.removeItem("subjectSelected3");
      window.sessionStorage.removeItem("subjectSelected4");
      window.sessionStorage.removeItem("subjectSelected5");
      window.sessionStorage.removeItem("classSectionsSelected1");
      window.sessionStorage.removeItem("classSectionsSelected2");
      window.sessionStorage.removeItem("classSectionsSelected3");
      window.sessionStorage.removeItem("classSectionsSelected4");
      window.sessionStorage.removeItem("classSectionsSelected5");
      window.sessionStorage.removeItem("labSelected1");
      window.sessionStorage.removeItem("labSelected2");
      window.sessionStorage.removeItem("labSelected3");
      window.sessionStorage.removeItem("labSelected4");
      window.sessionStorage.removeItem("labSelected5");

      if (window.sessionStorage.getItem("sendToEmailsWidget") == "yes") {
        window.sessionStorage.removeItem("sendToEmailsWidget");
        window.location.href = "/emailswidget";
      } else if (isItAddDrop[schools.indexOf(school)] == true) {
        if (window.sessionStorage.getItem("userPhone") == null) {
          window.sessionStorage.removeItem("sendToEmailsWidget");
          window.location.href = "/signup";
        } else if (
          freeUsersArray.includes(window.sessionStorage.getItem("userPhone"))
        ) {
          window.sessionStorage.setItem("userSchool", "UChicago");
          window.sessionStorage.removeItem("sendToEmailsWidget");
          window.location.href = "/getnotifications";
        } else if (window.sessionStorage.getItem("userName") != null) {
          window.sessionStorage.removeItem("sendToEmailsWidget");
          window.location.href = "/selectplan";
        } else {
          window.sessionStorage.removeItem("sendToEmailsWidget");
          window.location.href = "/signup";
        }
      } else {
        window.sessionStorage.removeItem("sendToEmailsWidget");
        window.location.href = "/signup";
      }
    }
  }

  function changeNewSchool(event) {
    setNewSchool(event.target.value);
    document.getElementById("newSchoolInput").classList.remove("form-error");
    setNewSchoolError("");
  }

  async function submitNewSchool() {
    if (newSchool == "") {
      setNewSchoolError("Please enter your school");
      document.getElementById("newSchoolInput").classList.add("form-error");
    } else if (buttonClicked2 == false) {
      setNewSchoolError("");
      setButtonClicked2(true);
      await firebase
        .firestore()
        .collection("schoolRequests")
        .add({
          school: newSchool,
          dateTime: currentDateInSeconds,
          dateTimeReverse: -currentDateInSeconds,
        })
        .then(() => {
          setSubmittedNewSchool(true);
          setButtonClicked2(false);
        });
    }
  }

  return (
    <section
      // {...props}
      className={outerClasses}
    >
      {/* <div className="container"> */}
      <div className={innerClasses} style={{paddingTop: "1rem"}}>
        {/* <div style={{marginLeft:'auto', marginRight:'auto', textAlign:'center', marginTop:'0px'}} class='emailsWidgetSub'> This email will include relevant information about their class. We don’t send emails to professors directly.</div> */}

        {addSchool == false ? (
          <>
            <SectionHeader
              tag="h1"
              data={sectionHeader}
              className="center-content"
              style={{paddingBottom: "24px", marginTop: "0.75rem"}}
            />
            <div className="tiles-wrap">
              <div className="tiles-item" style={{paddingTop: "0"}}>
                <div>
                  {/* We will text you when a spot in the class opens up. */}
                  <form style={{marginTop: "1rem", width: "100%"}}>
                    <fieldset>
                      <div className="mb-12"></div>
                      <select
                        name="school"
                        id="school"
                        class="form-select"
                        value={school}
                        onChange={(event) => {
                          setSchool(event.target.value);
                        }}
                      >
                        <option value="">Select Option...</option>
                        {schools.map((school) => (
                          <option key={school} value={school}>
                            {school}
                          </option>
                        ))}
                      </select>
                      <div
                        class="text-color-error fadeIn2Class"
                        style={{fontSize: "16px"}}
                      >
                        {schoolAlert}
                      </div>

                      <Button
                        type="button"
                        color="primary"
                        wide
                        style={{marginTop: "1rem"}}
                        onClick={submitSchool}
                      >
                        <div
                          class="noSelectText"
                          style={{pointerEvents: "none"}}
                        >
                          Next
                        </div>
                      </Button>

                      <div
                        style={{
                          marginTop: "2rem",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        }}
                      >
                        Don’t see your school?
                      </div>

                      <Button
                        type="button"
                        //color='primary'
                        onClick={() => setAddSchool(true)}
                        style={{width: "306px", marginTop: "1rem"}}
                      >
                        Request Your School
                      </Button>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {submittedNewSchool == false ? (
              <>
                <SectionHeader
                  tag="h1"
                  data={{title: "Request Your School"}}
                  className="center-content"
                  style={{
                    paddingBottom: "24px",
                    marginTop: "0.75rem",
                  }}
                />
                <div
                  class="tiles-item"
                  style={{marginLeft: "auto", marginRight: "auto"}}
                >
                  {/* <div style={{marginTop:'2rem', fontWeight:'bold', fontSize:'1rem'}}>
                  Request your school
                </div> */}

                  <input
                    label="Full name"
                    placeholder="Enter Your School"
                    labelHidden
                    id="newSchoolInput"
                    required
                    className="form-input"
                    onChange={changeNewSchool}
                    value={newSchool}
                    style={{marginTop: "0.5rem"}}
                  />
                  <div
                    //style={{marginTop:'0.5rem'}}
                    class="form-hint text-color-error"
                    style={{fontSize: "16px"}}
                  >
                    {newSchoolError}
                  </div>

                  <div className="mt-24 mb-32">
                    <Button
                      type="button"
                      color="primary"
                      onClick={submitNewSchool}
                      style={{width: "306px"}}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <div class="tiles-item">
                <div>
                  Thanks for submitting your school! Contact us at (612)
                  670-7721 for any questions.
                </div>
              </div>
            )}
          </>
        )}

        <div
          className="signin-bottom has-top-divider"
          style={{marginTop: "20rem"}}
        >
          <div className="pt-32 text-xs center-content text-color-low">
            Want to learn more about Class Clutch? <Link to="/">Home</Link>
          </div>
        </div>
      </div>
      {/* </div> */}
    </section>
  );
}

SelectSchool.propTypes = propTypes;
SelectSchool.defaultProps = defaultProps;

export default SelectSchool;
